import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, List, ListItem } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TrackerImage from '../../Assets/TrackerImage2.png'; // Make sure to import your image
import { JoinWaitlist, ResendOTP, VerifyOTP } from '../../middleware/UserMiddleware';
import { trackEvent } from '../../middleware/MixPanelLogger';
import androidDownload from '../../Assets/download-on-playstore.png';
import iosDownload from '../../Assets/download-on-appstore.png';

export default function NewTrackerHome() {
    const NAVBAR_HEIGHT = '65px';
    
    const handleAndroidOpen = () => {
        trackEvent("Clicked Android Download Button");
        window.open('https://play.google.com/store/apps/details?id=com.yemnews', '_blank')
    };

    const handleIOSOpen = () => {
        trackEvent("Clicked iOS Download Button");
        window.open('https://apps.apple.com/in/app/yemnews/id6446649370', '_blank')
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Vertical centering
                alignItems: 'center', // Horizontal centering
                minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`, // Full height minus navbar
                pt: NAVBAR_HEIGHT,
                px: '10%' // Use percentage-based padding for responsiveness
            }}>
                {/* Main Content - Two Columns */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between', // Distribute space between
                    alignItems: 'center',
                    width: '100%',
                }}>
                    {/* Left Column - Text Content */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start', // Left align
                        justifyContent: 'center',
                        width: '70%', // Increase width for text content
                        p: 4, // Padding inside the box
                        gap: 2
                    }}>
                        <Typography variant="h2" sx={{ mb: 2, fontSize: '55px', fontWeight: 'bold', textAlign: 'left', width: '550px', lineHeight: '1.3' }}>
                            <span style={{ color: '#2364ec' }}>Your assistant</span> to follow <span style={{ color: '#2364ec' }}>your interests</span>
                        </Typography>
                        <Typography variant="h5" sx={{ mb: 2, fontSize: '28px', fontWeight: 'normal', textAlign: 'left', width: '650px', lineHeight: '1.8' }}>
                            Algorithms get distracted by your recent behavior. Take full control of your feed with Yem.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, marginTop: 2, width: '100%' }}>
                            <img src={iosDownload} alt="Download on iOS" style={{ height: '57px', width: 'auto' }} onClick={() => handleIOSOpen()} />
                            <img src={androidDownload} alt="Download on Android" style={{ height: '60px', width: 'auto' }} onClick={() => handleAndroidOpen()} />
                        </Box>
                    </Box>

                    {/* Right Column - Image */}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40%', // Decrease width for the image
                    }}>
                        <img src={TrackerImage} alt="Tracker" style={{ width: '120%', height: 'auto' }} />
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}