import React, { useState } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { trackEvent } from '../../middleware/MixPanelLogger';

export default function ExploreFeedsCard({ feed, feedIndex, selectedFeedId = "" }) {

    return (
        <Link
            to={`/digest/${feed.userId}/${feed.feedId}`} style={{ textDecoration: 'none' }} key={feedIndex}
            onClick={() => trackEvent("Selected Feed", { "feedId": feed.feedId })}
        >
            <Card
                sx={{
                    marginBottom: 1,
                    px: 2,
                    pt: 1.8,
                    pb: 2.3,
                    border: 2,
                    borderColor: feed.feedId === selectedFeedId ? '#15803d' : '#e0e0e0',
                    borderRadius: 2,
                    shadow: 0,
                    mt: 2.5,
                    cursor: 'pointer', // Changes cursor to pointer on hover
                    '&:hover': {
                        borderColor: '#15803d',
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography gutterBottom sx={{ fontWeight: 'bold', marginBottom: 0, lineHeight: 'normal', fontSize: 24, textAlign: 'start' }}>
                        {feed.interest}
                    </Typography>
                    <ArrowForwardIosIcon sx={{ fontSize: '1.25rem' }} />
                </Box>
                <Typography sx={{ color: '#15803d', textAlign: 'start', mt: .5, fontSize: 20, fontWeight: 'bold', textAlign: 'start' }}>
                    By {feed.creatorName}
                </Typography>
                <Typography sx={{ color: 'grey.700', textAlign: 'start', mt: 1, mb: 2, fontSize: 20, fontWeight: 'normal', textAlign: 'start' }}>
                    {feed.description}
                </Typography>

                {/* <Typography variant="subtitle2" sx={{ fontWeight: 'normal', color: 'grey.600', mb: .5, mt: 2, textAlign: 'start' }}>
                    Sources
                </Typography> */}

                {/* <Grid container spacing={1}>
                    {feed.sources.map((source, index) => (
                        <Grid item key={index}>
                            <SourceItem source={source} />
                        </Grid>
                    ))}
                </Grid> */}
            </Card>
        </Link>
    )
}

function SourceItem({ source }) {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <Box>
            {!isHovering ?
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                        borderRadius: '50px',
                        border: '1px solid',
                    }}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    <img src={source.logo} style={{ width: 25, height: 25, borderRadius: 50 }} />
                </Box>
                :
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                        borderRadius: '50px',
                        border: '1px solid',
                        padding: 0.1
                    }}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    <img src={source.logo} style={{ width: 25, height: 25, borderRadius: 50 }} />
                    <Typography variant="body2" sx={{ color: 'inherit', fontSize: 12.5, mr: .5 }}>
                        {source.name}
                    </Typography>
                </Box>
            }
        </Box>
    );
}