import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { retrieveData } from '../../middleware/PersistentStorage';
import { FollowFeed } from '../../middleware/FeedsMiddleware';
import { trackEvent } from '../../middleware/MixPanelLogger';
import { useSelector } from 'react-redux';
import androidDownload from '../../Assets/download-on-playstore.png';
import iosDownload from '../../Assets/download-on-appstore.png';
import { JoinWaitlist } from '../../middleware/UserMiddleware';


export default function FollowPersonalizeButtons({ digest, isMobile = false }) {
    const NAVBAR_HEIGHT = '65px';
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleAndroidOpen = () => {
        trackEvent("Clicked Android Download Button");
        setOpen(true);
    };

    const handleIOSOpen = () => {
        trackEvent("Clicked iOS Download Button");
        window.open('https://apps.apple.com/in/app/yemnews/id6446649370', '_blank')
    }

    const handleClose = () => {
        trackEvent("Closed Join Waitlist Popup")
        setOpen(false);
        setError(''); // Reset error message when closing the dialog
    };

    const handleSubmit = () => {
        if (!email) {
            setError('Email is required.');
            return;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setError('Please enter a valid email address.');
                return;
            }
        }

        trackEvent("Joined Android Waitlist");
        JoinWaitlist(email, name, notes);
        setSubmitted(true);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            mb: 1,
            position: 'sticky', // Make the Box sticky
            top: 0, // Stick to the top of the parent
            zIndex: 1000, // Ensure it's above other content; adjust as necessary
            backgroundColor: 'white', // Match the background or set a specific color
        }}>
            {/* <Button
                onClick={() => trackEvent("Personalize Feed Clicked")}
                variant="outlined"
                sx={{
                    width: '100%',
                    mr: 1,
                    color: "#15803d",
                    borderColor: "#15803d",
                    '&:hover': {
                        bgcolor: "#15803d33"
                    }
                }}
                startIcon={<TuneIcon />}
            >
                Customize
            </Button> */}

            {digest &&
                <Typography variant="h4" fontWeight={"bold"} component="div" sx={{ py: 1, px: 1, width: '100%' }}>
                    {new Date(digest.createdDate).toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric' })}
                </Typography>
            }

            {isMobile ?
                <Box sx={{ display: 'block', justifyContent: 'flex-start', gap: 2, marginTop: 1, width: '100%' }}>
                    <img
                        src={iosDownload}
                        alt="Download on iOS"
                        style={{ height: '40px', width: 'auto', marginTop: 1, display: 'block', marginBottom: '8px' }}
                        onClick={() => handleIOSOpen()}
                    />
                    <img
                        src={androidDownload}
                        alt="Download on Android"
                        style={{ height: '43px', width: 'auto', display: 'block' }}
                        onClick={() => handleAndroidOpen()}
                    />
                </Box>
                :
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, marginTop: 1, width: '100%' }}>
                    <img src={iosDownload} alt="Download on iOS" style={{ height: '40px', width: 'auto', marginTop: 1 }} onClick={() => handleIOSOpen()} />
                    <img src={androidDownload} alt="Download on Android" style={{ height: '43px', width: 'auto' }} onClick={() => handleAndroidOpen()} />
                </Box>
            }

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{submitted ? 'Thank You!' : 'The Android app is in closed beta - Join the waitlist!'}</DialogTitle>
                <DialogContent>
                    {submitted ? (
                        <Typography variant="body1">
                            Thanks for joining the waiting list. We will be in touch with you soon.
                        </Typography>
                    ) : (
                        <>
                            <TextField
                                margin="dense"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{
                                    mb: 2,
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '6px', // Adjust the value as needed for rounding
                                    },
                                }}
                            />
                            {error && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    {error}
                                </Typography>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {submitted ? (
                        <Button onClick={handleClose}>Close</Button>
                    ) : (
                        <Button onClick={handleSubmit}>Continue</Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
}