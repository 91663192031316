import React, { useEffect, useState } from 'react'
import { Box, Typography, TextField, Button, Grid, Chip, IconButton, Stepper, Step, StepLabel, CircularProgress } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import CancelIcon from '@mui/icons-material/Cancel';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useSelector } from 'react-redux';
import Logo from './Logo';
import { ResendOTP, VerifyOTP } from '../../middleware/UserMiddleware';

export default function CreateFeedForm({
    topic,
    setTopic,
    audience,
    setAudience,
    sources,
    newSource,
    setNewSource,
    addSource,
    removeSource,
    frequency,
    setFrequency,
    email,
    setEmail,
    createPersonalFeed,
    format,
    setFormat
}) {
    const isLoadingFeed = useSelector((state) => state.feedCreation.showLoadingFeedCreation);
    const showWaitlistView = useSelector((state) => state.feedCreation.showWaitlistView);

    const isMobile = window.innerWidth < 600;

    const [OTPConfirmation, setOTPConfirmation] = useState(false);

    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Topic', 'Reader', 'Format', 'Sources', 'Frequency'];

    const isStepComplete = () => {
        switch (activeStep) {
            case 0: // Topic
                return topic !== '';
            case 1: // Audience
                return audience.reader !== '' && audience.requirement !== '';
            case 2:
                return format !== '';
            case 3: // Sources
                return true;
            case 4: // Frequency and Email
                return frequency !== '' && email !== '';
            default:
                return false;
        }
    };

    const handleNext = () => {
        if (isStepComplete()) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <WhatToFollowSection topic={topic} setTopic={setTopic} />;
            case 1:
                return <ImportantInformationAndAudienceSection audience={audience} setAudience={setAudience} />;
            case 2:
                return <FormatSection format={format} setFormat={setFormat} />;
            case 3:
                return <SourcesSection sources={sources} newSource={newSource} setNewSource={setNewSource} addSource={addSource} removeSource={removeSource} />;
            case 4:
                return <FrequencySection frequency={frequency} setFrequency={setFrequency} email={email} setEmail={setEmail} isMobile={isMobile} />;
            default:
                return 'Unknown step';
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '80vh',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%', maxWidth: isMobile ? '85%' : '650px' }}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel StepIconProps={{ style: { color: activeStep >= index ? "#2364ec" : "grey" } }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: isMobile ? '90%' : '650px',
            }}>
                {!isLoadingFeed && !showWaitlistView &&
                    <>
                        {getStepContent(activeStep)}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1, color: "#2364ec" }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={activeStep === steps.length - 1 ? createPersonalFeed : handleNext}
                                sx={{ mt: 1, mr: 1, backgroundColor: '#2364ec' }}
                                endIcon={<ArrowForwardIcon />}
                                disabled={!isStepComplete()}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </>
                }


                {showWaitlistView && OTPConfirmation &&
                    <WaitlistView email={email} />
                }

                {showWaitlistView && !OTPConfirmation &&
                    <OTPConfirmationView email={email} interest={topic} setOTPConfirmation={setOTPConfirmation} />
                }

                {isLoadingFeed &&
                    <LoadingView />
                }
            </Box>
        </Box>
    );
}

const WhatToFollowSection = ({ topic, setTopic }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CenterFocusWeakIcon sx={{ fontSize: 30, mr: 1.5, color: '#2364ec' }} />
                <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                    What's the focus of your digest?
                </Typography>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: '500', mb: 2, color: 'grey.700', fontSize: '1.1rem' }}>
                e.g. OpenAI, Generative AI in Healthcare, Crypto Regulations in India, Everything about Musk and SpaceX, etc...
            </Typography>
            <TextField
                fullWidth
                placeholder='Type here...'
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                margin="normal"
                multiline
                rows={2}
                sx={{ border: '1px solid #15803d', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0,0,0,0.1)' }}
            />
        </Box>
    )
};

const ImportantInformationAndAudienceSection = ({ audience, setAudience }) => {
    const [showInterestField, setShowInterestField] = useState(false);

    useEffect(() => {
        if (audience.reader !== '') {
            setShowInterestField(true);
        }
    }, [audience.reader]);

    const handleSelection = (choice) => {
        setAudience({ ...audience, reader: choice, requirement: '' });
        setShowInterestField(true);
    };

    const handleRequirementChange = (event) => {
        setAudience({ ...audience, requirement: event.target.value });
    };

    const handleReaderChange = () => {
        setAudience({ ...audience, reader: '', requirement: '' });
        setShowInterestField(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PersonIcon sx={{ fontSize: 30, mr: 2, color: '#2364ec' }} />
                {audience.reader === '' ?
                    <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                        Who is this for?
                    </Typography>
                    :
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.5rem', mr: 2 }}>
                            Why do you need this digest?
                        </Typography>
                        {/* <Button variant="text" color="secondary" onClick={handleReaderChange}>
                            Change
                        </Button> */}
                    </Box>
                }
            </Box>
            {audience.reader === '' &&
                <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2, mt: 2 }}>
                    <Button
                        variant={audience.reader === 'myself' ? "contained" : "outlined"}
                        sx={{
                            width: '45%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 2,
                            textTransform: 'none',
                            backgroundColor: audience.reader === 'myself' ? '#15803d' : 'transparent', // Green background for active
                            color: audience.reader === 'myself' ? '#fff' : '#15803d', // White text for active, green text for inactive
                            '&:hover': {
                                backgroundColor: audience.reader === 'myself' ? '#145a32' : 'rgba(21, 128, 61, 0.08)', // Darker green on hover for active
                                borderColor: '#15803d' // Ensuring consistent border color on hover for outlined
                            },
                            border: '1px solid #15803d' // Green border for outlined
                        }}
                        onClick={() => handleSelection('myself')}
                    >
                        <AccountCircleIcon fontSize="large" sx={{ color: audience.reader === 'myself' ? '#fff' : '#15803d' }} />
                        <Typography variant="h6">Myself</Typography>
                    </Button>
                    <Button
                        variant={audience.reader === 'someone_else' ? "contained" : "outlined"}
                        sx={{
                            width: '45%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 2,
                            textTransform: 'none',
                            backgroundColor: audience.reader === 'someone_else' ? '#15803d' : 'transparent', // Green background for active
                            color: audience.reader === 'someone_else' ? '#fff' : '#15803d', // White text for active, green text for inactive
                            '&:hover': {
                                backgroundColor: audience.reader === 'someone_else' ? '#145a32' : 'rgba(21, 128, 61, 0.08)', // Darker green on hover for active
                                borderColor: '#15803d' // Ensuring consistent border color on hover for outlined
                            },
                            border: '1px solid #15803d' // Green border for outlined
                        }}
                        onClick={() => handleSelection('someone_else')}
                    >
                        <GroupIcon fontSize="large" sx={{ color: audience.reader === 'someone_else' ? '#fff' : '#15803d' }} />
                        <Typography variant="h6">Someone Else</Typography>
                    </Button>
                </Box>
            }
            {showInterestField && (
                <Box>
                    <AudienceExampleSection audience={audience} />
                    <TextField
                        fullWidth
                        placeholder='Type here. Be as descriptive as possible...'
                        value={audience.requirement}
                        onChange={handleRequirementChange}
                        margin="normal"
                        multiline
                        rows={4}
                        sx={{ border: '1px solid #15803d', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0,0,0,0.1)' }}
                    />
                </Box>
            )}
        </Box>
    );
};

const AudienceExampleSection = ({ audience }) => {
    return (
        <Box>
            {audience.reader === 'myself' ?
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ color: 'grey.700', fontWeight: 'medium' }}>
                            e.g. To quickly catch up on what's happened
                        </Typography>

                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ color: 'grey.700', fontWeight: 'medium' }}>
                            e.g. I work in this industry so I need to be well informed of what's happening
                        </Typography>
                    </Box>
                </>
                :
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ color: 'grey.700', fontWeight: 'medium' }}>
                            e.g. To keep my R&D team updated on HealthTech innovations affecting our products
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ color: 'grey.700', fontWeight: 'medium' }}>
                            e.g. To help my Web3 community track regulatory changes impacting their projects
                        </Typography>
                    </Box>
                </>
            }
        </Box>
    );
};

const FormatSection = ({ format, setFormat }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CenterFocusWeakIcon sx={{ fontSize: 30, mr: 2, color: '#2364ec' }} />
                <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                    How do you want your daily digest?
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2, gap: 3, mt: 2 }}>
                <Button
                    variant={format === 'newsletter' ? "contained" : "outlined"}
                    sx={{
                        width: '45%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 2,
                        textTransform: 'none',
                        backgroundColor: format === 'newsletter' ? '#15803d' : 'transparent', // Green background for active
                        color: format === 'newsletter' ? '#fff' : '#15803d', // White text for active, green text for inactive
                        '&:hover': {
                            backgroundColor: format === 'newsletter' ? '#145a32' : 'rgba(21, 128, 61, 0.08)', // Darker green on hover for active
                            borderColor: '#15803d' // Ensuring consistent border color on hover for outlined
                        },
                        border: '1px solid #15803d' // Green border for outlined
                    }}
                    onClick={() => setFormat('newsletter')}
                >
                    <MailOutlineIcon fontSize="large" sx={{ color: format === 'newsletter' ? '#fff' : '#15803d' }} />
                    <Typography variant="h6">Newsletter</Typography>
                </Button>
                <Button
                    variant={format === 'podcast' ? "contained" : "outlined"}
                    sx={{
                        width: '45%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 2,
                        textTransform: 'none',
                        backgroundColor: format === 'podcast' ? '#15803d' : 'transparent', // Green background for active
                        color: format === 'podcast' ? '#fff' : '#15803d', // White text for active, green text for inactive
                        '&:hover': {
                            backgroundColor: format === 'podcast' ? '#145a32' : 'rgba(21, 128, 61, 0.08)', // Darker green on hover for active
                            borderColor: '#15803d' // Ensuring consistent border color on hover for outlined
                        },
                        border: '1px solid #15803d' // Green border for outlined
                    }}
                    onClick={() => setFormat('podcast')}
                >
                    <PodcastsIcon fontSize="large" sx={{ color: format === 'podcast' ? '#fff' : '#15803d' }} />
                    <Typography variant="h6">Podcast</Typography>
                </Button>
                <Button
                    variant={setFormat === 'reels' ? "contained" : "outlined"}
                    sx={{
                        width: '45%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 2,
                        textTransform: 'none',
                        backgroundColor: format === 'reels' ? '#15803d' : 'transparent', // Green background for active
                        color: format === 'reels' ? '#fff' : '#15803d', // White text for active, green text for inactive
                        '&:hover': {
                            backgroundColor: format === 'reels' ? '#145a32' : 'rgba(21, 128, 61, 0.08)', // Darker green on hover for active
                            borderColor: '#15803d' // Ensuring consistent border color on hover for outlined
                        },
                        border: '1px solid #15803d' // Green border for outlined
                    }}
                    onClick={() => setFormat('reels')}
                >
                    <YouTubeIcon fontSize="large" sx={{ color: format === 'reels' ? '#fff' : '#15803d' }} />
                    <Typography variant="h6">Reels</Typography>
                </Button>
            </Box>
        </Box>
    )
};

const SourcesSection = ({ sources, newSource, setNewSource, addSource, removeSource }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <LibraryBooksIcon sx={{ fontSize: 30, mr: 2, color: '#2364ec' }} />
                <Typography variant="h5" sx={{ mb: 0, fontWeight: 'bold' }}>
                    Where should Yem read from?
                </Typography>
            </Box>

            <Typography variant="body1" sx={{ mt: .8, color: 'grey.500' }}>
                Yem can follow Google Alerts, News Feeds, Twitter, LinkedIn, etc...

            </Typography>
            <Typography variant="body1" sx={{ mb: 3, mt: 1, color: 'grey.500' }}>
                Leave empty if you want Yem to find the best sources for you.
            </Typography>
            <Grid container spacing={2}>
                {sources.map((source, index) => (
                    <Grid item key={index}>
                        <Chip
                            label={source}
                            onDelete={() => removeSource(source)}  // Call removeSource with the source name
                            deleteIcon={<CancelIcon fontSize="small" />}  // Smaller delete icon
                            sx={{
                                bgcolor: '#fff',
                                color: '#15803d',
                                border: `1px solid #15803d`,
                                fontWeight: 'bold',
                                '&:hover': {
                                    bgcolor: '#e7f5ee',
                                },
                                '& .MuiChip-deleteIcon': {
                                    color: '#15803d',  // Change the delete icon color to grey
                                },
                                fontSize: '1rem', // Making the text a bit larger
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 2 }}>
                <TextField
                    label="Enter URL or name here..."
                    size="small"
                    value={newSource}
                    onChange={(e) => setNewSource(e.target.value)}
                    sx={{ width: '80%' }}
                />
                <IconButton
                    onClick={addSource}
                    sx={{
                        color: '#15803d',
                    }}
                >
                    <AddCircleIcon style={{ fontSize: 30 }} />
                </IconButton>
            </Box>
        </Box>
    );
};

const FrequencySection = ({ frequency, setFrequency, email, setEmail, isMobile }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
            <Box sx={{ flexGrow: 1, width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <NotificationImportantIcon sx={{ fontSize: 30, mr: 2, alignSelf: 'flex-start', color: '#2364ec' }} />
                    <Typography variant="h5" sx={{ mb: 0, fontWeight: 'bold' }}>
                        How often do you want your digest?
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', mb: 2 }}>
                    <TextField
                        placeholder="e.g. daily at 9pm, every monday & thursday at 8am..."
                        variant="outlined"
                        size="medium"
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        sx={{ border: '1px solid #15803d', borderRadius: 2, width: isMobile ? '100%' : '65%' }}
                    />
                </Box>

                <TextField
                    placeholder="Enter the email to send your digests..."
                    variant="outlined"
                    size="medium"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ border: '1px solid #15803d', borderRadius: 2, width: isMobile ? '100%' : '65%' }}
                />
            </Box>
        </Box>
    );
};

const WaitlistView = ({ email }) => {
    return (
        <Box sx={{
            display: 'flex', // Use Flexbox for the layout
            flexDirection: 'column', // Align children vertically
            justifyContent: 'center', // Center children vertically
            alignItems: 'center', // Center children horizontally
            textAlign: 'center' // Center the text
        }}>
            <Logo></Logo>
            <Typography variant="h6" sx={{ mt: 3, mb: 1, px: 3, fontWeight: 'medium', textAlign: 'center' }}>
                We're experiencing high demand. The first digest will be sent to {email} within 12 hours!
            </Typography>

            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#2364ec',
                    padding: '10px 16px',
                    borderRadius: 2,
                    mt: 3,
                    textTransform: 'none',
                    fontSize: '1rem',
                }}
                onClick={() => window.location.reload()}
            >
                Make Another Digest
            </Button>
        </Box>
    )
};

const LoadingView = () => {
    return (
        <Box sx={{
            display: 'flex', // Use Flexbox for the layout
            flexDirection: 'column', // Align children vertically
            justifyContent: 'center', // Center children vertically
            alignItems: 'center', // Center children horizontally
            textAlign: 'center' // Center the text
        }}>
            <Box>
                <Logo pulse={true}></Logo>
                <Typography variant="h5" sx={{ mt: 3, mb: 1, px: 3, fontWeight: 'medium', textAlign: 'center' }}>
                    Creating Your Digest...
                </Typography>
            </Box>
        </Box>
    );
};

const OTPConfirmationView = ({ email, interest, setOTPConfirmation }) => {
    const [otp, setOTP] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleOTPChange = (event) => {
        setOTP(event.target.value);
    };

    const handleVerifyClick = async () => {
        if (otp.length === 4) {
            setLoading(true);
            const verified = await VerifyOTP(email, interest, otp);

            setLoading(false);
            if (verified) {
                setOTPConfirmation(true);
            } else {
                setError('Invalid OTP. Please try again.');
            }
        } else {
            setError('Please enter a valid 4-digit OTP.');
        }
    };

    const handleResendOTPClick = async () => {
        try {
            setLoading(true);
            const response = await ResendOTP(interest, email);
            setLoading(false);
        } catch (error) {
            setError('An error occurred. Please try again later.');
            setLoading(false);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: 3
        }}>
            <Typography variant="h6" sx={{ mt: 3, mb: 1, fontWeight: 'medium' }}>
                Please enter the OTP sent to {email}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                    value={otp}
                    onChange={handleOTPChange}
                    error={!!error}
                    helperText={error}
                    label="OTP"
                    variant="outlined"
                    inputProps={{ maxLength: 4 }}
                    sx={{ mt: 2, mb: 2, width: '70%', maxWidth: 300 }}
                />
                <Button
                    variant="contained"
                    onClick={handleVerifyClick}
                    disabled={loading}
                    sx={{
                        backgroundColor: '#2364ec',
                        padding: '10px 16px',
                        borderRadius: 2,
                        textTransform: 'none',
                        fontSize: '1rem',
                    }}
                >
                    {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Verify'}
                </Button>
            </Box>
            <Typography
                variant="body"
                onClick={handleResendOTPClick}
                sx={{
                    cursor: 'pointer',
                    color: '#2364ec',
                    textDecoration: 'underline',
                    mt: 2,
                    display: 'inline-block',
                    ':hover': {
                        textDecoration: 'none',
                    },
                    opacity: loading ? 0.5 : 1
                }}
                component="span"
            >
                Resend OTP
            </Typography>

            <Typography
                variant="body"
                sx={{
                    mt: 3,
                    color: '#666',
                    maxWidth: '80%',
                    textAlign: 'center',
                    lineHeight: '1.5'
                }}
            >
                To ensure you receive your digests, please check your spam folder and mark Yem's emails as "Not Spam".
            </Typography>
        </Box>
    );
};