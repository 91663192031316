import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useState } from "react";
import logo from '../../Assets/logo.png';
import Typography from '@mui/material/Typography';
import { useSelector } from "react-redux";
import { Box, Button, Divider } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from "../../middleware/AuthHelper";

export default function Navbar() {
    const navStyle = {
        position: 'fixed',
        zIndex: 1000,
        width: '100%',
        backgroundColor: 'white',
        color: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 0px',
        borderBottom: '1px solid #eaeaea',
    };

    const ulStyle = {
        display: 'flex',
        marginLeft: 'auto',
        gap: 30,
        marginRight: 50,
        listStyleType: 'none' // This line removes the dots
    };

    const signInButtonContainerStyle = {
        marginRight: '10px',
    };

    const [usecaseHover, setUsecaseHover] = useState(false);

    return (
        <nav style={navStyle}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                <img src={logo} alt="Logo" style={{ width: 40, height: 40, paddingLeft: 30 }} />
                <span style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: 'black',
                    marginLeft: '10px',
                }}>Yem</span>
            </Link>
            <ul style={ulStyle}>
                <CustomLink to="/contactUs"><Typography variant="h6" sx={{ mr: 3 }}>Contact Us</Typography></CustomLink>
            </ul>
        </nav>
    );
}

function UserInfoDisplay({ username }) {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 'auto', // This pushes the sign-in button to the right
        }}>
            <Button
                variant="outlined"
                startIcon={<AccountCircleIcon style={{ height: 30, width: 30, marginLeft: 5 }} />}
                sx={{
                    padding: 1,
                    borderRadius: '10px',
                    textTransform: 'none',
                    color: '#15803d',
                    borderColor: '#15803d'
                }}
            >
                <Typography variant="body1">{username}</Typography>
            </Button>
            <LogoutIcon
                sx={{ fontSize: 25, ml: 2, color: "gray", cursor: 'pointer' }}
                onClick={() => { logout() }}
            />
        </Box>
    );
}

function CustomLink({ to, children, style }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    const [hover, setHover] = useState(false);

    const combinedStyle = {
        ...style,
        color: isActive ? '#15803d' : (!hover ? 'black' : '#15803d'),
        fontWeight: '',
        textDecoration: 'none',
    };

    return (
        <li
            style={{ ...combinedStyle, display: 'flex', alignItems: 'center', position: 'relative' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Link to={to} style={combinedStyle}>
                {children}
            </Link>
        </li>
    );
}