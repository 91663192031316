import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, ListItem, List, ListItemIcon } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TrackerImage from '../../Assets/TrackerImage1.png'; // Make sure to import your image
import { JoinWaitlist } from '../../middleware/UserMiddleware';
import Navbar from '../Desktop/navbar';
import { trackEvent } from '../../middleware/MixPanelLogger';
import androidDownload from '../../Assets/download-on-playstore.png';
import iosDownload from '../../Assets/download-on-appstore.png';

export default function NewMobileHome() {
    const handleAndroidOpen = () => {
        trackEvent("Clicked Android Download Button");
        window.open('https://play.google.com/store/apps/details?id=com.yemnews', '_blank')
    };

    const handleIOSOpen = () => {
        trackEvent("Clicked iOS Download Button");
        window.open('https://apps.apple.com/in/app/yemnews/id6446649370', '_blank')
    }

    return (
        <Box>
            <Navbar />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Vertical centering
                alignItems: 'center', // Horizontal centering
                minHeight: '100vh', // Full height of the viewport
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start', // Left align
                    justifyContent: 'center',
                }}>
                    <Typography variant="h2" sx={{ mb: 2, fontSize: '55px', fontWeight: 'bold', textAlign: 'left', lineHeight: '1.3', padding: 2 }}>
                        <span style={{ color: '#2364ec' }}>Your assistant</span> to follow <span style={{ color: '#2364ec' }}>your interests</span>
                    </Typography>
                    <Typography variant="h5" sx={{ mb: 2, fontSize: '25px', fontWeight: 'normal', textAlign: 'left', lineHeight: '1.8', padding: 2 }}>
                        Algorithms get distracted by your recent behavior. Use Yem to follow only what you want, effortlessly.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, marginTop: 2, width: '100%' }}>
                        <img src={iosDownload} alt="Download on iOS" style={{ height: '57px', width: 'auto' }} onClick={() => handleIOSOpen()} />
                        <img src={androidDownload} alt="Download on Android" style={{ height: '60px', width: 'auto' }} onClick={() => handleAndroidOpen()} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
