import { createSlice } from "@reduxjs/toolkit";

export const yemCopilotSlice = createSlice({
    name: "yemCopilotSlice",
    initialState: {
        coPilotIsLoading: false,
        groups: [],
    },
    reducers: {
        setGroups(state, action) {
            state.groups = action.payload;
        },
        setCoPilotIsLoading(state, action) {
            state.coPilotIsLoading = action.payload;
        }
    },
});

export const { setGroups, setCoPilotIsLoading } = yemCopilotSlice.actions;

export default yemCopilotSlice.reducer;