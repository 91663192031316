// redux/reducers/PopupSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        message: null,
    },
    reducers: {
        setPopupMessage: (state, action) => {
            state.message = action.payload;
        },
        clearPopupMessage: (state) => {
            state.message = null;
        },
    },
});

export const { setPopupMessage, clearPopupMessage } = popupSlice.actions;

export default popupSlice.reducer;