import mixpanel from 'mixpanel-browser';

mixpanel.init('a14452acdccfc74bb0aab549a832e387', { debug: true, track_pageview: true, persistence: 'localStorage' });

export async function identifyUser(userId) {
    if (window.location.href.includes('localhost')) {
        console.log('DEV: MixPanel User Identified: ', userId);
        return;
    }

    console.log('MixPanel User Identified: ', userId);
    mixpanel.identify(userId);
}

export async function trackEvent(eventName, eventProperties) {
    if (window.location.href.includes('localhost')) {
        console.log('DEV: MixPanel Event: ' + eventName + " " + JSON.stringify(eventProperties));
        return;
    }

    console.log('MixPanel Event: ' + eventName + " " + JSON.stringify(eventProperties));
    mixpanel.track(eventName, eventProperties)
}

export function getDistinctId() {
    const distinct_id = mixpanel.get_distinct_id();
    console.log('MixPanel Distinct ID: ', distinct_id);
    return distinct_id;
}