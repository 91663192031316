// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAKmcD976_nFIGaH-Vk59EDSwLxYYoFbuc",
  authDomain: "yemintentmonitor.firebaseapp.com",
  projectId: "yemintentmonitor",
  storageBucket: "yemintentmonitor.appspot.com",
  messagingSenderId: "131762332398",
  appId: "1:131762332398:web:a38ea384d63bfc286cc9ff",
  measurementId: "G-FR2VX1BER5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth, analytics };