import { endpoint } from "../Assets/GlobalVariables";
import { setFeeds, setIsLoadingExploreFeeds } from "../redux/reducers/ExploreFeedsSlice";
import { clearPersonalFeeds, setDigest, setIsLoading, setNoDigest } from "../redux/reducers/PersonalFeedsSlice";
import { setGroups } from "../redux/reducers/YemCopilotSlice";
import { AxiosGet, AxiosGetImage, AxiosPost } from "./AxiosHelper";

export async function GetExploreFeeds(dispatch) {
    dispatch(setIsLoadingExploreFeeds(true));
    const response = await AxiosGet(`${endpoint}/getPublicFeeds`);

    dispatch(setIsLoadingExploreFeeds(false));
    dispatch(setFeeds(response));
}

export async function GetDigest(feedCreatorId, feedId, dispatch) {
    dispatch(clearPersonalFeeds());
    dispatch(setIsLoading(true));

    const response = await AxiosGet(`${endpoint}/getDigest/${feedCreatorId}/${feedId}`);

    if (response) {
        dispatch(setIsLoading(false));
        dispatch(setDigest(response));
    } else {
        dispatch(setIsLoading(false));
        dispatch(setNoDigest(true));
    }
}

export async function GetGroups(feedCreatorId, feedId, dispatch) {
    const response = await AxiosGet(`${endpoint}/getGroups/${feedCreatorId}/${feedId}`);

    dispatch(setGroups(response));
}

export async function RemoveArticlesFromGroups(feedCreatorId, feedId, articles, dispatch) {
    const response = await AxiosPost(`${endpoint}/removeArticlesInGroups`, {
        creatorId: feedCreatorId,
        feedId,
        articles
    });

    return response;
}

export async function ReGroupArticles(feedCreatorId, feedId, articles, dispatch) {
    const response = await AxiosPost(`${endpoint}/reGroupArticles`, {
        creatorId: feedCreatorId,
        feedId,
        articles
    });

    return response;
}

export async function FollowFeed(feedCreatorId, feedId, currentUserId, email, frequency) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const response = await AxiosPost(`${endpoint}/followFeed`, {
        feedCreatorId,
        feedId,
        followerId: currentUserId,
        email,
        frequency,
        timezone
    });

    return response;
}

export async function GetShareUpdateImage(digestId, updateId) {
    const response = await AxiosGetImage(`${endpoint}/sharableImage/${digestId}/${updateId}`);

    return response;
}