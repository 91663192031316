import React, { useEffect, useState } from 'react';
import { GetGroups, ReGroupArticles, RemoveArticlesFromGroups } from '../../middleware/FeedsMiddleware';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Typography, Checkbox, Box, Button } from '@mui/material';
import { setCoPilotIsLoading } from '../../redux/reducers/YemCopilotSlice';
import Logo from '../Common/Logo';

export default function YemCopilot({ feedCreatorId, feedId }) {
    const dispatch = useDispatch();
    const groups = useSelector((state) => state.yemCoPilot.groups);
    const isLoading = useSelector((state) => state.yemCoPilot.coPilotIsLoading);

    const [checkedArticles, setCheckedArticles] = useState({});

    useEffect(() => {
        refresh();
    }, [dispatch, feedCreatorId, feedId]);

    const handleCheckboxChange = (articleId) => {
        setCheckedArticles((prev) => {
            return {
                ...prev,
                [articleId]: !prev[articleId],
            };
        });
    };

    async function refresh() {
        dispatch(setCoPilotIsLoading(true));
        setCheckedArticles({});
        await GetGroups(feedCreatorId, feedId, dispatch);
        dispatch(setCoPilotIsLoading(false));
    }

    async function handleRemoveItems() {
        dispatch(setCoPilotIsLoading(true));
        await RemoveArticlesFromGroups(feedCreatorId, feedId, Object.keys(checkedArticles), dispatch);
        refresh();
    };

    async function handleGroupItems() {
        dispatch(setCoPilotIsLoading(true));
        await ReGroupArticles(feedCreatorId, feedId, Object.keys(checkedArticles), dispatch);
        refresh();
    };

    return (
        <Box sx={{ pt: 10 }}>

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', p: 2 }}>
                <Button variant="contained" color="primary" onClick={handleRemoveItems}>
                    Remove Items
                </Button>
                <Button variant="contained" color="secondary" onClick={handleGroupItems}>
                    Group Items
                </Button>
            </Box>

            {isLoading &&
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh', overflow: 'auto' }}>
                    <Logo pulse={true} />
                    <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                        Making Changes...
                    </Typography>
                </Box>
            }

            {!isLoading &&
                <Grid container spacing={2} sx={{ p: 3, pt: 1 }}>
                    {groups.map((group, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper elevation={3} sx={{ padding: 2, borderRadius: '10px' }}>
                                {group.articles.length > 1 && (
                                    <Button
                                        onClick={() => {
                                            group.articles.forEach((article) => {
                                                handleCheckboxChange(article.id);
                                            });
                                        }}
                                    >
                                        Select All
                                    </Button>
                                )}
                                {group.articles.map((article, articleIndex) => (
                                    <Box
                                        key={articleIndex}
                                        sx={{ display: 'flex', alignItems: 'center', mt: articleIndex === 0 ? 0 : 2 }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCheckboxChange(article.id);
                                        }}
                                    >
                                        <Checkbox
                                            checked={!!checkedArticles[article.id]}
                                            onChange={(event) => {
                                                event.stopPropagation();
                                                handleCheckboxChange(article.id);
                                            }}
                                            sx={{ padding: 0, marginRight: 1 }}
                                        />
                                        <Typography variant="body1">{article.title}</Typography>
                                    </Box>
                                ))}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            }
        </Box >
    );
}
