import React from 'react';
import { Box, Typography } from '@mui/material';

export default function DailyOverviewCard({ dailyOverview }) {
    return (
        <Box sx={{
            background: "#e2fdea", // Light green background for freshness
            p: 2, // Padding inside the card
            mb: 4, // Margin bottom for spacing from the next element
            borderRadius: "8px", // Rounded corners
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
            borderLeft: "6px solid #10b981", // A green accent on the left border for emphasis
            position: 'relative', // For absolute positioning of the icon
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography gutterBottom variant="h5" component="div" fontWeight="bold">
                    Peek into today
                </Typography>
            </Box>
            {dailyOverview.map((item, index) => (
                <Box key={index} sx={{ display: 'block', mb: 1.5 }}>
                    <Typography fontWeight="normal" sx={{ fontSize: 16 }}>
                        <strong>{item.keyaspect}: </strong>{item.value}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
}
