import React, { useState, useEffect } from 'react';
import { Modal, TextField, Button, Typography, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { storeData, retrieveData } from '../../middleware/PersistentStorage';
import { useSelector } from 'react-redux';
import { FollowFeed } from '../../middleware/FeedsMiddleware';
import { trackEvent } from '../../middleware/MixPanelLogger';

export default function TempFollowPopup({ open, handleClose, digest }) {
    const userId = useSelector((state) => state.user.anonymousUserId);

    const [email, setEmail] = useState('');
    const [frequency, setFrequency] = useState('');
    const [confirmedMethod, setConfirmedMethod] = useState('');

    useEffect(() => {
        const savedEmail = retrieveData('userEmailId1');
        const savedFrquency = retrieveData('frequency1');
        if (savedEmail && savedFrquency) {
            setEmail(savedEmail);
            setFrequency(savedFrquency);
        }
    }, []);

    const handleInputConfirm = () => {
        if (email && frequency) {
            storeData('userEmailId1', email);
            storeData('frequency1', frequency);
            trackEvent("Followed feed using email");
            FollowFeed(digest.feedCreatorId, digest.feedId, userId, email, frequency);
            setConfirmedMethod('email');
            handleClose();
        }
    };

    const ConfirmationMessage = () => (
        <Box sx={{ mb: 2 }}>
            <EmailIcon sx={{ fontSize: 50 }} />
            <Typography variant="h6" component="h2" gutterBottom>
                {`${digest.interest} feed will be sent to `}
                <span style={{ color: '#15803d' }}>{email}</span>
                {` ${frequency}.`}
            </Typography>
            <Button variant="contained" color="primary" fullWidth onClick={handleClose} sx={{ mt: 2 }}>
                OK
            </Button>
        </Box>
    );

    const body = (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 4,
            pb: 4,
            px: 4,
            borderRadius: 2,
            textAlign: 'center'
        }}>
            {confirmedMethod ? (
                <ConfirmationMessage />
            ) : (
                <>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Enter details to subscribe:
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        margin="dense"
                        id="frequency"
                        label="Frequency (e.g. daily at 9AM)"
                        type="text"
                        fullWidth
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        onClick={handleInputConfirm}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!email || !frequency} // Disable button if fields are empty
                    >
                        Subscribe
                    </Button>
                </>
            )}
        </Box>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            {body}
        </Modal>
    );
}
