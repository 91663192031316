export const storeData = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error("Storing Local Error for key: " + key, error);
        // Error saving data
    }
};

export const retrieveData = (key) => {
    try {
        const value = localStorage.getItem(key);
        if (value !== null) {
            return JSON.parse(value);
        }
    } catch (error) {
        console.error("Retrieving Local Error for key: " + key, error);
        // Error retrieving data
    }
};
