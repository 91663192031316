import { GoogleAuthProvider, signInWithPopup, signInAnonymously } from "firebase/auth";
import { auth } from "../firebase/FirebaseInit";
import { logEvent } from "./Logger";
import { anonUserSignedIn, createAnonUser } from "./UserMiddleware";
import { setAnonymousUserID, setUserID } from "../redux/reducers/UserSlice";
import { identifyUser } from "./MixPanelLogger";

export function signInWithGooglePopup(anonymousUserId, dispatch) {
    logEvent("Clicked Sign In With Google");
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            // ...

            dispatch(setUserID(user.uid));
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
}

export function signInAnonymouslyHelper(dispatch) {
    console.log("Signing in anonymously");
    signInAnonymously(auth)
        .then((result) => {
            // Signed in 
            const user = result.user;
            identifyUser(user.uid);
            dispatch(setAnonymousUserID(user.uid));
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // Handle Errors here, such as displaying a notification to the user
            console.error("Error signing in anonymously:", errorCode, errorMessage);
        });
}

export function logout() {
    logEvent("Clicked Logout");
    auth.signOut();
}