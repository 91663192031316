import React, { useState } from 'react'
import { Box, Collapse, Divider, Grid, Typography, Button, IconButton } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import IosShareIcon from '@mui/icons-material/IosShare';
import CircularProgress from '@mui/material/CircularProgress'
import { GetShareUpdateImage } from '../../middleware/FeedsMiddleware';
import { useSelector } from 'react-redux';
import { downloadBlobImage, shareImage } from '../../middleware/AxiosHelper';
import { trackEvent } from '../../middleware/MixPanelLogger';

export default function UpdateDisplay({ update }) {

    const isMobile = window.innerWidth <= 768
    const [showKeyDetails, setShowKeyDetails] = useState(true);

    const toggleKeyDetails = () => {
        if (showKeyDetails) {
            trackEvent("Collapse Key Details For Update", { "updateId": update.id, "updateTitle": update.title });
        } else {
            trackEvent("Expand Key Details For Update", { "updateId": update.id, "updateTitle": update.title });
        }
        setShowKeyDetails(!showKeyDetails);
    };

    return (
        <Box>

            {isMobile ?
                <Box onClick={toggleKeyDetails}>
                    {update.updateImage && <img src={update.updateImage} style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '4px', marginBottom: 10 }} />}
                    <Typography fontWeight={"bold"} sx={{ fontSize: 25 }}>
                        {update.title}
                    </Typography>
                </Box>
                :
                <Box onClick={toggleKeyDetails}>
                    {update.updateImage && <img src={update.updateImage} style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '4px', marginBottom: 10 }} />}
                    <Typography fontWeight={"bold"} sx={{ fontSize: 27, mt: 2 }}>
                        {update.title}
                    </Typography>
                </Box>
            }

            <Collapse in={showKeyDetails}>
                {
                    update.summary.map((item, index) => (
                        <Box sx={{ display: 'flex', mb: 1, mt: 1.7 }}>
                            <Typography sx={{ mr: .9, fontSize: 15 }}>
                                •
                            </Typography>
                            <Typography sx={{ fontSize: 16 }}>
                                {item}
                            </Typography>
                        </Box>
                    ))
                }
            </Collapse>

            <SourcesGrid update={update} />

            <Divider sx={{ mb: 2, mt: 1, background: "#bbf7d0", height: 1 }} />
        </Box >
    )
}

function SourcesGrid({ update }) {
    const digest = useSelector((state) => state.personalFeeds.digest);

    const [showSources, setShowSources] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [isGettingSharableImage, setIsGettingSharableImage] = useState(false);

    const toggleSources = () => {
        if (showSources) {
            trackEvent("Collapse Sources For Update", { "updateId": update.id, "updateTitle": update.title });
        } else {
            trackEvent("Expand Sources For Update", { "updateId": update.id, "updateTitle": update.title });
        }

        setShowSources(!showSources);
    };

    async function shareUpdate() {
        trackEvent("Shared Update", { "updateId": update.id, "updateTitle": update.title });
        setIsGettingSharableImage(true);

        const imageBlob = await GetShareUpdateImage(digest.digestId, update.id);

        const file = new File([imageBlob], "shared_image.png", { type: 'image/png' });

        if (navigator.canShare && navigator.canShare({ files: [file] })) {
            try {
                await navigator.share({
                    files: [file],
                });
                console.log('Image shared successfully');
            } catch (error) {
                console.error('Error sharing the image:', error);
            }
        } else {
            const url = URL.createObjectURL(imageBlob);
            window.open(url, '_blank');
            setTimeout(() => URL.revokeObjectURL(url), 1000); // Delay revoking the URL
            console.log('Opened image in a new tab.');
        }

        setIsGettingSharableImage(false);
    }

    function getDomainFromURL(url) {
        const endUrl = new URL(url);
        return endUrl.hostname;
    }

    function getDomainName(url) {
        const endUrl = new URL(url);
        const hostnameParts = endUrl.hostname.split('.');
        let domainName = hostnameParts[hostnameParts.length - 2];

        //capitalize the first letter:
        domainName = domainName.charAt(0).toUpperCase() + domainName.slice(1);

        return domainName;
    }

    function dislikeUpdate() {
        setIsDisliked(!isDisliked);
    }

    return (
        <>
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button onClick={(event) => {
                    event.stopPropagation();
                    toggleSources();
                }} size="small" sx={{ color: '#15803d', textTransform: 'none', fontSize: 13.5 }}>
                    {update.sources.length} source{update.sources.length > 1 ? "s" : ""} used {showSources ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="body2"
                        component="div"
                        sx={{
                            mt: .5,
                            mb: .5,
                            fontSize: 13.5,
                            fontWeight: 'medium',
                            color: 'grey.600',
                            textAlign: 'right',
                            pr: 1
                        }}>
                        {update.displayDateTime}
                    </Typography>

                    <IconButton onClick={(event) => {
                        dislikeUpdate();
                        event.stopPropagation();
                    }}>
                        <ThumbDownOffAltIcon sx={{
                            color: isDisliked ? '#15803d' : 'grey.600',
                            height: 22,
                            width: 22,
                            ':hover': {
                                color: '#15803d',
                            },
                        }} />
                    </IconButton>

                    {isGettingSharableImage
                        ?
                        <CircularProgress size={18}></CircularProgress>
                        :
                        <IconButton
                            onClick={(event) => {
                                shareUpdate();
                                event.stopPropagation();
                            }}
                        >
                            <IosShareIcon
                                sx={{
                                    color: isDisliked ? '#15803d' : 'grey.600',
                                    height: 22,
                                    width: 22,
                                    ':hover': {
                                        color: '#15803d',
                                    },
                                }}
                            />
                        </IconButton>}
                </Box>
            </Box>
            <Collapse in={showSources}>
                <Grid container spacing={1}>
                    {update.sources.map((source, index) => (
                        <Grid item key={index}>
                            <a href={source.url} target="_blank" rel="noreferrer" onClick={(event) => {
                                trackEvent("Clicked Source", { "updateId": update.id, "updateTitle": update.title, "source": source.url });
                                event.stopPropagation();
                            }}>
                                <Box
                                    sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '5px',
                                        borderRadius: '50px',
                                        bgcolor: '#dcfce7',
                                        color: 'black',
                                        padding: .3
                                    }}
                                >
                                    <img src={`https://logo.clearbit.com/${getDomainFromURL(source.url)}`} style={{ width: 20, height: 20, borderRadius: 50 }} />
                                    <Typography variant="body2" sx={{ color: 'inherit', fontSize: 12.5 }}>
                                        {getDomainName(source.url)}
                                    </Typography>
                                    <OpenInNewIcon sx={{ height: 11, width: 11, pr: 1 }} />
                                </Box>
                            </a>
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </>
    )
}