import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearPopupMessage } from '../../redux/reducers/PopupSlice';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function Popup() {
    const message = useSelector((state) => state.popup.message);
    const dispatch = useDispatch();
    const open = Boolean(message);

    return (
        <Dialog
            open={open}
            onClose={() => dispatch(clearPopupMessage())}
            aria-labelledby="popup-dialog-title"
            aria-describedby="popup-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="popup-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(clearPopupMessage())} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
