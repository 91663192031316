import { Box, Button, Card, Divider, Fab, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import Logo from '../Common/Logo';
import AddIcon from '@mui/icons-material/Add';
import { trackEvent } from '../../middleware/MixPanelLogger';
import ExploreFeedsCard from '../Common/ExploreFeedsCard';

export default function ExploreFeeds() {

    const feeds = useSelector(state => state.exploreFeeds.publicFeeds)
    const isLoading = useSelector(state => state.exploreFeeds.isLoading)

    useEffect(() => {
        trackEvent("Opened Explore Feeds");
    }, [])

    return (
        <Box sx={{ mx: 1.5, my: 2 }}>
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
                Explore Feeds
            </Typography>
            <Divider sx={{ backgroundColor: '#15803d', height: 3 }} />
            {!isLoading && feeds.map((feed, feedIndex) => (
                <ExploreFeedsCard feed={feed} feedIndex={feedIndex} />
            ))}

            {isLoading && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh', overflow: 'auto' }}>
                    <Logo pulse={true} />
                    <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                        Loading Feeds
                    </Typography>
                </Box>
            )}

            <Link to="/create" style={{ textDecoration: 'none' }}>
                <Fab
                    aria-label="add"
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: '50%',
                        transform: 'translateX(50%)',
                    }}
                >
                    <Box component="div" sx={{ display: 'inline-block' }}> {/* Inline-block for child */}
                        <Link to="/create" style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    borderRadius: 2,
                                    width: 250,
                                    height: 50,
                                    mt: 1,
                                    mb: .5,
                                    fontSize: 19,
                                    backgroundColor: '#2364ec',
                                    textTransform: 'none'
                                }}
                                endIcon={<AddIcon />}
                            >
                                Make A Digest
                            </Button>
                        </Link>
                    </Box>
                </Fab>
            </Link>
        </Box>
    )
}
