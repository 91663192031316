import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/UserSlice'
import popupReducer from './reducers/PopupSlice'
import personalFeeds from './reducers/PersonalFeedsSlice'
import feedCreationSlice from './reducers/FeedCreationSlice'
import exploreFeedsSlice from './reducers/ExploreFeedsSlice'
import yemCopilotSlice from './reducers/YemCopilotSlice'

export default configureStore({
    reducer: {
        user: userReducer,
        popup: popupReducer,
        personalFeeds: personalFeeds,
        feedCreation: feedCreationSlice,
        exploreFeeds: exploreFeedsSlice,
        yemCoPilot: yemCopilotSlice,
    },
})