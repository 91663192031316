// src/components/Redirect.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Redirect = () => {
    const location = useLocation();

    useEffect(() => {
        function getQueryParams() {
            const params = new URLSearchParams(location.search);
            return {
                feedCreatorId: params.get('feedCreatorId'),
                feedId: params.get('feedId')
            };
        }

        function redirect() {
            const { feedCreatorId, feedId } = getQueryParams();
            const iosScheme = `yemnews://digest/${feedCreatorId}/${feedId}`;
            const androidScheme = `yemnews://digest/${feedCreatorId}/${feedId}`;
            const webUrl = `https://yemnews.com/digest/${feedCreatorId}/${feedId}`;
            const ua = navigator.userAgent || navigator.vendor || window.opera;

            let appScheme;

            if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
                appScheme = iosScheme;
            } else if (/android/i.test(ua)) {
                appScheme = androidScheme;
            } else {
                window.location.href = webUrl;
                return;
            }

            // Attempt to open the app
            const timeout = setTimeout(function () {
                window.location.href = webUrl;
            }, 1500);

            window.location.href = appScheme;
        }

        redirect();
    }, [location]);

    return (
        <div>
            <p>Redirecting...</p>
        </div>
    );
};

export default Redirect;