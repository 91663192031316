import { endpoint } from "../Assets/GlobalVariables"
import { AxiosGet, AxiosPost } from "../middleware/AxiosHelper"
import { setLoadingFeedCreation, setShowWaitlistView } from "../redux/reducers/FeedCreationSlice";

export async function createAnonUser(anonUserId) {
    const response = await AxiosPost(endpoint + "/createNewAnonUser", { anonymousUserId: anonUserId })
    return response;
}

export async function UserCreatePersonalFeed(anonUserId, topic, reader, requirement, format, sources, frequency, email, dispatch) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const response = await AxiosPost(endpoint + "/addFeedForUser", {
        anonymousUserId: anonUserId,
        interest: topic,
        reader: reader,
        requirement: requirement,
        format: format,
        sources: sources,
        frequency: frequency,
        email: email,
        timeZone: timeZone
    })

    dispatch(setLoadingFeedCreation(false));
    dispatch(setShowWaitlistView(true));

    return response;
}

export async function VerifyOTP(email, interest, otp) {
    const response = await AxiosGet(endpoint + `/verifyOTP/${email}/${interest}/${otp}`);

    return response.status === 'valid';
}

export async function ResendOTP(interest, email) {
    const response = await AxiosPost(endpoint + "/resendOTP", {
        interest: interest,
        email: email
    });

    return response.data.status === 'success';
}

export async function JoinWaitlist(email, name, notes) {
    const response = await AxiosPost(endpoint + "/joinWaitlist", {
        email: email,
        name: name,
        notes: notes
    });

    return response.status === 'success';
}

export async function SendSampleNewsletter(email) {
    const response = await AxiosPost(endpoint + "/SendSampleNewsletter", {
        email: email
    });

    return response.status === 'success';
}