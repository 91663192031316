import React from 'react';
import logo from '../../Assets/logo.png';

const Logo = ({ pulse = false }) => {
    return (
        <>
            <style>
                {`
                    @keyframes pulse {
                        0%, 100% { transform: scale(1); }
                        50% { transform: scale(1.1); }
                    }
                `}
            </style>
            <img
                src={logo}
                alt="Pulsing Logo"
                style={{
                    width: 170,
                    height: 170,
                    marginBottom: 15,
                    animation: pulse ? 'pulse 1.5s infinite' : 'none'
                }}
            />
        </>
    );
};

export default Logo;
