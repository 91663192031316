import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Divider, List, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { GetDigest } from '../../middleware/FeedsMiddleware';
import { clearPersonalFeeds } from '../../redux/reducers/PersonalFeedsSlice';
import Logo from '../Common/Logo';
import UpdateDisplay from '../Common/UpdateDisplay';
import MediaPlayer from '../Common/MediaPlayer';
import DailyOverviewCard from '../Common/DailyOverviewCard';
import UpdatesList from '../Common/UpdatesList';
import FollowPersonalizeButtons from '../Common/FollowPersonalizeButtons';
import TempFollowPopup from '../Common/TempFollowPopup';

export default function Digest() {
    const { feedCreatorId, feedId } = useParams();
    const dispatch = useDispatch();

    const digest = useSelector((state) => state.personalFeeds.digest);
    const isLoading = useSelector((state) => state.personalFeeds.isLoading);
    const noDigest = useSelector((state) => state.personalFeeds.noDigest);

    const [openFollowPopup, setOpenFollowPopup] = useState(false);

    useEffect(() => {
        GetDigest(feedCreatorId, feedId, dispatch);
    }, [feedCreatorId, feedId]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {/* Sticky Header with Back Button and Follow/Personalize Buttons */}
            <Box sx={{
                position: 'sticky', top: 0, zIndex: 1100,
                backgroundColor: 'background.paper', // Match the theme's background or choose a color
                px: 2
            }}>
                <Link to="/explore" style={{ textDecoration: 'none' }}>
                    <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={() => dispatch(clearPersonalFeeds())}
                    >
                        Back
                    </Button>
                </Link>
                <FollowPersonalizeButtons setFollowPopupOpen={setOpenFollowPopup} digest={digest} isMobile={true}></FollowPersonalizeButtons>
            </Box>

            <Box sx={{
                overflowY: 'auto', flexGrow: 1,
                px: 2 // Padding on the sides for the scrollable content
            }}>
                {!isLoading && !noDigest && digest && (
                    <>
                        <TempFollowPopup open={openFollowPopup} handleClose={() => setOpenFollowPopup(false)} digest={digest}></TempFollowPopup>

                        <List sx={{ width: '100%', padding: 0 }}>
                            {/* <Typography variant="h4" fontWeight={"bold"} component="div" sx={{ mb: 1 }}>
                                {digest.interest}
                            </Typography> */}

                            {digest.dailyOverview &&
                                <DailyOverviewCard dailyOverview={digest.dailyOverview} />
                            }

                            {(digest.podcastId && digest.podcastId !== "") &&
                                <Box sx={{
                                    background: "#fde2f1", // Soft pink background for a calm, yet distinct feel
                                    p: 2, // Padding inside the card
                                    mb: 4, // Margin bottom for spacing from the next element
                                    borderRadius: "8px", // Rounded corners
                                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                                    borderLeft: "6px solid #db7093", // Pink accent on the left border for emphasis
                                    width: '93%'
                                }}>
                                    <Typography align="left" variant='h5' fontWeight='bold' sx={{ mb: 3 }}>Today's Yemcast</Typography>
                                    <MediaPlayer
                                        filePath={'podcasts/' + digest.podcastId + '.mp3'}
                                    />
                                </Box>
                            }

                            <UpdatesList updates={digest.focusedUpdates.length > 0 ? digest.focusedUpdates : digest.updates.filter((update) => update.significant)} />
                        </List>
                    </>
                )}

                {
                    noDigest && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh', overflow: 'auto' }}>
                            <Logo />
                            <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                                No digest available
                            </Typography>
                        </Box>
                    )
                }

                {
                    isLoading && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh', overflow: 'auto' }}>
                            <Logo pulse={true} />
                            <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                                Loading Digest...
                            </Typography>
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
}
