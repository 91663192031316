import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Fab, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DigestView from './DigestView';
import Logo from '../Common/Logo';
import { trackEvent } from '../../middleware/MixPanelLogger';
import ExploreFeedsCard from '../Common/ExploreFeedsCard';

export default function ExploreFeedsView() {
    const { feedCreatorId, feedId } = useParams();
    const feeds = useSelector(state => state.exploreFeeds.publicFeeds);
    const isLoading = useSelector(state => state.exploreFeeds.isLoading);

    const [selectedFeedCreatorId, setSelectedFeedCreatorId] = useState(null);
    const [selectedFeedId, setSelectedFeedId] = useState(null);

    const NAVBAR_HEIGHT = '85px';

    useEffect(() => {
        if (feedId && feedCreatorId) {
            setSelectedFeedCreatorId(feedCreatorId);
            setSelectedFeedId(feedId);
            trackEvent("Opened Explore Feeds With Link", { "feedId": feedId, "feedCreatorId": feedCreatorId })
        } else {
            trackEvent("Opened Explore Feeds")
        }
    }, [feedId, feedCreatorId]);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center', // Adjusted for horizontal centering
            alignItems: 'center', // Adjusted for vertical centering
            pt: NAVBAR_HEIGHT,
            minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
            px: 20
        }}>
            <Grid container spacing={2} sx={{ width: 1200 }}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 3 }}>
                        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Explore Feeds
                        </Typography>

                        <Box component="div" sx={{ display: 'inline-block' }}> {/* Inline-block for child */}
                            <Link to="/create" style={{ textDecoration: 'none' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        borderRadius: 2,
                                        width: 250,
                                        height: 50,
                                        mt: 1,
                                        mb: .5,
                                        mr: 8,
                                        fontSize: 19,
                                        backgroundColor: '#2364ec',
                                        textTransform: 'none'
                                    }}
                                    endIcon={<AddIcon />}
                                >
                                    Make A Digest
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Divider sx={{ backgroundColor: '#15803d', height: 3, mr: 10 }} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        overflowY: 'auto',
                        height: '80vh'
                    }}> {/* Parent container with text-align center */}
                        {!isLoading && feeds.map((feed, feedIndex) => (
                            <ExploreFeedsCard feed={feed} feedIndex={feedIndex} selectedFeedId={selectedFeedId}></ExploreFeedsCard>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    {selectedFeedId ?
                        <DigestView feedId={selectedFeedId} feedCreatorId={selectedFeedCreatorId} />
                        :
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh', overflow: 'auto' }}>
                            <Logo />
                            <Typography variant="h5" sx={{ mt: 2, textAlign: 'center', fontWeight: 'bold' }}>
                                Select a feed to view
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 1, textAlign: 'center', fontWeight: 'normal' }}>
                                Scroll to see more feeds
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}
