import React from 'react';
import { Typography } from '@mui/material';

function MobileContactUs() {
    return (
        <div>
            <Typography variant="h3" component="div" gutterBottom fontWeight='bold' color='#15803d'>
                Hi I'm Surya, the founder of Yem. I'll help you out :)
            </Typography>
            <Typography variant="h3" component="div" gutterBottom fontWeight='bold' color='#15803d' marginTop={10}>
                Please email me at suryamvnn@gmail.com
            </Typography>
        </div>
    );
}

export default MobileContactUs;
