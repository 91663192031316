import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/FirebaseInit';
import { setAnonymousUserID, setSessionID } from './redux/reducers/UserSlice';
import { useEffect, useState } from 'react';
import { GetExploreFeeds } from './middleware/FeedsMiddleware';
import { signInAnonymouslyHelper } from './middleware/AuthHelper';
import Navbar from './components/Desktop/navbar';
import ContactUs from './components/Desktop/ContactUs';
import Popup from './components/Desktop/Popup';
import MobileHome from './components/Mobile/MobileHome';
import MobileContactUs from './components/Mobile/MobileContactUs';
import TrackerHome from './components/Desktop/TrackerHome';
import Demo from './components/Desktop/TrackerDemo';
import ExploreFeeds from './components/Mobile/ExploreFeeds';
import Digest from './components/Mobile/Digest';
import ExploreFeedsView from './components/Desktop/ExploreFeedsView';
import YemCopilot from './components/Desktop/YemCopilot';
import NewTrackerHome from './components/Desktop/NewTrackerHome';
import NewMobileHome from './components/Mobile/NewMobileHome';
import Redirect from './components/Common/Redirect';
import { getDistinctId, identifyUser, trackEvent } from './middleware/MixPanelLogger';

function App() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    trackEvent("Website Loaded");
  }, []);

  useEffect(() => {
    dispatch(setSessionID(uuidv4()));
    GetExploreFeeds(dispatch);

    function handleResize() {
      setIsMobile(window.innerWidth <= 768); // Assuming 768px as a breakpoint for mobile devices
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      dispatch(setAnonymousUserID(uid));

      if (getDistinctId().includes("device")) {
        console.log("MixPanel Distinct ID is not set, setting it to UID: ", uid);
        identifyUser(uid);
      }

      console.log("User is already signed in:", uid);
    } else {
      console.log("No user signed in, proceeding with anonymous sign-in.");
      signInAnonymouslyHelper(dispatch);
    }
  });

  if (isMobile) {
    return (
      <Routes>
        <Route path="/" element={<NewMobileHome />} />
        <Route path="/create" element={<Demo />} />
        <Route path="/contactUs" element={<MobileContactUs />} />
        <Route path="/explore" element={<ExploreFeeds />} />
        <Route path="/digest/:feedCreatorId/:feedId" element={<Digest />} />
        <Route path="/redirect" element={<Redirect />} />
      </Routes>
    );
  }

  return (
    <div>
      <Navbar />
      <Popup />
      <div>
        <Routes>
          <Route path="/" element={<NewTrackerHome />} />
          <Route path="/create" element={<Demo />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/explore" element={<ExploreFeedsView />} />
          <Route path="/digest/:feedCreatorId/:feedId" element={<ExploreFeedsView />} />
          <Route path="/yemCoPilot" element={<YemCopilot feedCreatorId={"sample"} feedId={"AIStartups"} />} />
          <Route path="/redirect" element={<Redirect />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
