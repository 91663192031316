import React, { useEffect, useState } from 'react';
import {
    Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UserCreatePersonalFeed } from '../../middleware/UserMiddleware';
import { clearDisplayStates, setLoadingFeedCreation } from '../../redux/reducers/FeedCreationSlice';
import { trackEvent } from '../../middleware/MixPanelLogger';
import CreateFeedForm from '../Common/CreateFeedForm';

const NAVBAR_HEIGHT = '100px';

const Demo = () => {
    const dispatch = useDispatch();

    const [topic, setTopic] = useState('');
    const [audience, setAudience] = useState({ reader: 'myself', requirement: '' });
    const [sources, setSources] = useState([]);
    const [newSourceEntry, setNewSourceEntry] = useState('');
    const [frequency, setFrequency] = useState('');
    const [email, setEmail] = useState('');
    const [format, setFormat] = useState('');

    const anonymousUserId = useSelector((state) => state.user.anonymousUserId);

    useEffect(() => {
        trackEvent("Opened Create Feed");
    }, []);

    function addSource() {
        if (newSourceEntry === '') {
            return;
        }

        const sourceObj = {
            logo: '',
            name: newSourceEntry,
            url: ''
        }
        setSources(prev => [...prev, sourceObj.name]);
        setNewSourceEntry('');
    }

    function removeSource(source) {
        setSources(sources.filter(s => s !== source));
    }

    const createPersonalFeed = () => {
        trackEvent("Finish Create Feed Clicked");
        const finalTopic = topic.trim();
        const finalAudience = audience.reader.trim();
        const finalRequirement = audience.requirement.trim();
        const finalSources = sources.map(source => source.trim());
        const finalFrequency = frequency.trim();
        const finalEmail = email.trim();
        const finalFormat = format.trim();

        dispatch(clearDisplayStates());
        dispatch(setLoadingFeedCreation(true));

        UserCreatePersonalFeed(
            anonymousUserId,
            finalTopic,
            finalAudience,
            finalRequirement,
            finalFormat,
            finalSources,
            finalFrequency,
            finalEmail,
            dispatch
        );
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center', // Adjusted for horizontal centering
            alignItems: 'center', // Adjusted for vertical centering
            pt: NAVBAR_HEIGHT,
            minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}>
            <CreateFeedForm
                topic={topic}
                setTopic={setTopic}
                audience={audience}
                setAudience={setAudience}
                sources={sources}
                newSource={newSourceEntry}
                setNewSource={setNewSourceEntry}
                addSource={addSource}
                removeSource={removeSource}
                frequency={frequency}
                setFrequency={setFrequency}
                email={email}
                setEmail={setEmail}
                createPersonalFeed={createPersonalFeed}
                format={format}
                setFormat={setFormat}
            />
        </Box >
    );
};

export default Demo;