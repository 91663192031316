import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import store from '../redux/store';

const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=575c2479-204e-4b13-b7eb-381804b96d46;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/'
    }
});
appInsights.loadAppInsights();

function getUserandSessionId() {
    const sessionId = store.getState().user.sessionID;
    const anonymousUserId = store.getState().user.anonymousUserId;

    return {
        sessionID: sessionId,
        anonymousUserId: anonymousUserId
    }
}

export async function logEvent(eventName, properties = {}) {
    try {
        const telemetryProperties = {
            ...properties,
            ...getUserandSessionId(),
            source: 'web_app'
        };

        appInsights.trackEvent({ name: eventName, properties: telemetryProperties });

        console.log("LOG EVENT: " + eventName + ", " + JSON.stringify(telemetryProperties));
    } catch (error) {
        console.log("LOG EVENT ERROR FOR: " + eventName + ", " + error);
    }
}

export async function logError(name, error) {
    try {
        const telemetryProperties = {
            ...getUserandSessionId(),
            source: 'web_app',
            name: name
        };

        appInsights.trackException({ exception: new Error(error), properties: telemetryProperties });

        console.log("LOG ERROR: " + name + ", " + error);
    } catch (error) {
        console.log("LOG ERROR ERROR FOR: " + name + ", " + error);
    }
}
