import { createSlice } from '@reduxjs/toolkit'

//create a slice to store the userId
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        userId: null,
        anonymousUserId: null,
        userName: null,
        userEmail: null,
        sessionID: null,
    },
    reducers: {
        //set userId
        setUserID: (state, action) => {
            state.userId = action.payload
        },
        //set anonymousUserId
        setAnonymousUserID: (state, action) => {
            state.anonymousUserId = action.payload
        },
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload
        },
        //set sessionID
        setSessionID: (state, action) => {
            state.sessionID = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setUserID,
    setAnonymousUserID,
    setSessionID,
    setUserName,
    setUserEmail,
} = userSlice.actions

export default userSlice.reducer