import React, { useState } from 'react';
import { Box, Card, List, Typography, Button } from '@mui/material';
import UpdateDisplay from '../Common/UpdateDisplay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { trackEvent } from '../../middleware/MixPanelLogger';
import NewsletterUpdateDisplay from './NewsletterUpdateDisplay';

export default function UpdatesList({ updates }) {
    // State to manage the visibility of less significant updates
    const [isExpanded, setIsExpanded] = useState(false);

    // Toggle function to change isExpanded state
    const toggleExpand = () => {
        if (isExpanded) {
            trackEvent("Clicked Hide Less Significant Stories");
        } else {
            trackEvent("Clicked Show Less Significant Stories");
        }
        
        setIsExpanded(!isExpanded);
    };

    return (
        <List sx={{ width: '100%', padding: 0 }}>
            {updates.length > 0 ?
                <Box sx={{ mb: 5, mt: 2 }}>
                    {/* <Typography align="left" variant='h5' fontWeight='bold' sx={{ mb: 4, mt: 1 }}>Today's YemFeed</Typography> */}
                    {updates.map((update, index) => (
                        // <NewsletterUpdateDisplay update={update} key={index} />
                        <UpdateDisplay update={update} key={index} />
                    ))}
                </Box>
                :
                <Card
                    sx={{
                        mb: 4,
                        p: 1.5,
                        boxShadow: 0,
                        border: '1px solid #e0e0e0',
                        transition: '0.3s',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Box>
                        <Typography variant="h7" component="div">
                            Nothing relevant for you today
                        </Typography>
                    </Box>
                </Card>
            }
        </List>
    );
}