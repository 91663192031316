import { createSlice } from '@reduxjs/toolkit'

export const feedCreationSlice = createSlice({
    name: 'feedCreationSlice',
    initialState: {
        sources: [],
        samples: [],
        showLoadingFeedCreation: false,
        showWaitlistView: false,
        showArticlesView: false,
        SSELoadingMessages: {}
    },
    reducers: {
        setSources: (state, action) => {
            state.sources = action.payload;
        },
        addSource: (state, action) => {
            state.sources.push(action.payload);
        },
        setSamples: (state, action) => {
            state.samples = action.payload;
        },
        setLoadingFeedCreation: (state, action) => {
            state.showLoadingFeedCreation = action.payload;
        },
        setShowWaitlistView: (state, action) => {
            state.showWaitlistView = action.payload;
        },
        setShowArticlesView: (state, action) => {
            state.showArticlesView = action.payload;
        },
        setSSELoadingMessages: (state, action) => {
            const { source, status } = action.payload;
            state.SSELoadingMessages[source] = status;
        },
        clearDisplayStates: (state) => {
            state.showLoadingFeedCreation = false;
            state.showWaitlistView = false;
            state.showArticlesView = false;
            state.SSELoadingMessages = {};
        }
    },
})

export const {
    setSources,
    addSource,
    setSamples,
    setLoadingFeedCreation,
    setShowWaitlistView,
    setShowArticlesView,
    setSSELoadingMessages,
    clearDisplayStates
} = feedCreationSlice.actions;

export default feedCreationSlice.reducer
