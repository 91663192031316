import React from 'react';
import { Typography } from '@mui/material';

function ContactUs() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ maxWidth: 500 }}>

                <Typography variant="h4" component="div" gutterBottom fontWeight='bold' color='#15803d'>
                    Hi I'm Surya, the founder of Yem. I'll help you out :)
                </Typography>
                <Typography variant="h4" component="div" gutterBottom fontWeight='bold' color='#15803d' marginTop={10}>
                    Email me: founder@yemnews.com
                </Typography>
            </div>
        </div>
    );
}

export default ContactUs;
