
import axios from 'axios';
import store from '../redux/store';
import { logError } from './Logger';
import { Buffer } from 'buffer';

export function getHeaders() {
    const anonymousUserId = store.getState().user.anonymousUserId;
    const sessionId = store.getState().user.sessionID;
    return { headers: { 'anonymousUserId': anonymousUserId, 'sessionId': sessionId, 'afterPivot': "true" } }
}

export async function AxiosGet(endpoint) {
    try {
        const headers = getHeaders();
        const response = await axios.get(endpoint, headers);
        return response.data;
    } catch (error) {
        logError("Axios Get Error for endpoint: " + endpoint, error);
        throw error;
    }
}

export async function AxiosPost(endpoint, data) {
    try {
        const response = await axios.post(endpoint, data, getHeaders());
        return response;
    } catch (error) {
        logError("Axios Post Error for endpoint: " + endpoint, error);
        throw error;
    }
}

export async function AxiosGetImage(endpoint) {
    try {
        console.log(`Requesting image from endpoint: ${endpoint}`); // Log the endpoint URL

        const response = await axios.get(endpoint, { responseType: 'blob' });

        console.log('Received response with status:', response.status);
        console.log('Response headers:', response.headers);
        console.log('Content-Type:', response.headers['content-type']);

        return response.data; // This is already a Blob
    } catch (error) {
        console.error('Error fetching the image:', error);
    }
}