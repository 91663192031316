import { createSlice } from '@reduxjs/toolkit';

export const exploreFeedsSlice = createSlice({
    name: 'exploreFeedsSlice',
    initialState: {
        publicFeeds: [],
        isLoading: false,
    },
    reducers: {
        setFeeds: (state, action) => {
            state.publicFeeds = action.payload;
        },
        setIsLoadingExploreFeeds: (state, action) => {
            state.isLoading = action.payload;
        }
    },
});

export const { setFeeds, setIsLoadingExploreFeeds } = exploreFeedsSlice.actions;

export default exploreFeedsSlice.reducer;