import { createSlice } from '@reduxjs/toolkit'

export const personalFeedsSlice = createSlice({
    name: 'personalFeeds',
    initialState: {
        digest: null,
        isLoading: false,
        noDigest: false,
    },
    reducers: {
        setDigest: (state, action) => {
            state.digest = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setNoDigest: (state, action) => {
            state.noDigest = action.payload;
        },
        clearPersonalFeeds: (state) => {
            state.digest = null;
            state.isLoading = false;
            state.noDigest = false;
        }
    },
})

export const { setDigest, setIsLoading, setNoDigest, clearPersonalFeeds } = personalFeedsSlice.actions;

export default personalFeedsSlice.reducer
