import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css'; // Import the styles
import '../../Assets/MediaPlayerStyles.css';
import { trackEvent } from '../../middleware/MixPanelLogger';

const MediaPlayer = ({ filePath }) => {
    const [audioUrl, setAudioUrl] = useState("");

    useEffect(() => {
        const fetchAudioUrl = async () => {
            const storage = getStorage();
            const storageRef = ref(storage, filePath);
            const url = await getDownloadURL(storageRef);
            setAudioUrl(url);
        };

        fetchAudioUrl();
    }, [filePath]);

    // Handle play action
    const handlePlay = () => {
        trackEvent("Played Podcast");
    };

    // Handle pause action
    const handlePause = (e) => {
        trackEvent("Paused Podcast at " + Math.round(e.target.currentTime) + " seconds");
    };

    const handleSeeking = (e) => {
        trackEvent('Podcast Dragged to ' + Math.round(e.target.currentTime) + " seconds");
    };

    return (
        <AudioPlayer
            style={{
                marginTop: '10px',
                border: '1px solid #15803d',
            }}
            src={audioUrl}
            autoPlayAfterSrcChange={false}
            autoPlay={false}
            onPlay={handlePlay} // Register the onPlay event handler
            onPause={handlePause} // Register the onPause event handler
            onSeeking={handleSeeking} // Register the onSeeking event handler
        />
    );
};

export default MediaPlayer;
